import { entered } from 'redux-routable'
import { of } from 'rxjs'
import { filter, map, mergeMap } from 'rxjs/operators'
import { fetchDistinctFieldValues } from '~/data/fieldValues'
import { switchTo } from '~/utils/operators'
import { getPatientId } from '../data/patientId'
import { fetchPatientInfo } from '../data/patientInfo'
import router from '../router'

export default (action$, state$) =>
  action$.pipe(
    filter(entered(router)),
    switchTo(state$),
    map(getPatientId),
    mergeMap(patientId =>
      of(
        fetchPatientInfo.requested(patientId),
        fetchDistinctFieldValues([
          'are_there_any_drug_allergies',
          'aspire_program_enrolled',
          'care_preferences',
          'contact_current_location',
          'contact_location_type',
          'encounter_assessment_status',
          'ethnicity',
          'gender',
          'hospice_eligible',
          'hotspotter_plan',
          'internet_access_available',
          'language',
          'referral_mailing_type',
          'marital_status',
          'patient_diagnosis',
          'patient_internal_substatus',
          'patient_status',
          'person_salutation',
          'physician_specialty',
          'primary_care_giver',
          'preferred_video_applications',
          'provider_prognosis',
          'problem_status',
          'referral_facilitator',
          'referral_source_type',
          'referred_patient_substatus',
          'salutation',
          'timeline_event_type',
          'us_state',
          'video_devices_available',
          'yes_no',
        ])
      )
    )
  )
