import { combineEpics } from 'redux-observable'
import portal from './data/other'
import appEntered from './epics/appEntered'
import downloadDocument from './epics/downloadDocument'

export default combineEpics(
  appEntered,
  downloadDocument,
  portal.timelineEventsEpic,
  portal.vitalsEpic,
  portal.carePlanEpic,
  portal.lastEncounterCarePlanEpic,
  portal.contactsEpic,
  portal.stakeholdersEpic,
  portal.careTeamEpic,
  portal.prescriptionsEpic,
  portal.preferredPharmacyEpic,
  portal.hospitalizationsEpic,
  portal.ltcsEpic,
  portal.snfsEpic,
  portal.encountersEpic,
  portal.ordersEpic,
  portal.dischargesEpic,
  portal.onCallsEpic,
  portal.attachmentsEpic
)
