import { Redirect, Route, Router } from 'redux-routable'
import key from './key'

export const PARTNER_PORTAL_PATIENT_LIST = `${key}.patientList`
export const PARTNER_PORTAL_WELCOME_SCREEN = `${key}.welcomeScreen`

export default Router([
  Route(PARTNER_PORTAL_WELCOME_SCREEN, '/'),
  Route(PARTNER_PORTAL_PATIENT_LIST, '/patient-list'),
  Redirect(PARTNER_PORTAL_WELCOME_SCREEN),
])
