import 'react-hot-loader'

import { ASPIRE_API_PATH } from 'ahc-config'
import axios from 'axios'
import React from 'react'
import ReactDOM from 'react-dom'
import { sync } from 'redux-routable'
import { appInitialized } from '~/data/root'
import { loggedIn, loggedOut } from '~/data/session'
import history from '~/history'
import keycloak from '~/utils/security/keycloak'
import { SESSION_USER, getInitializationData, getItem } from '~/utils/storage'
import telemetry from '~/utils/telemetry'
import router from './router'
import store from './store'

// Render the React app
const renderApp = () =>
  import(
    /* webpackChunkName: "PartnerPortalAppContainer" */ '~/partnerPortal/App'
  ).then(({ default: App }) => {
    ReactDOM.render(
      <App router={router} store={store} history={history} />,
      document.getElementById('root')
    )
  })

// Implement hot module reloading for Redux reducer changes
const reloadRedux = () => {
  import('./reducer').then(({ default: reducer }) => {
    store.replaceReducer(reducer)
  })
}

const getUser = () => keycloak.user

// Application entry point, bootstraps and renders the application
const initApp = () =>
  // Check if online
  axios
    .get(`${ASPIRE_API_PATH}/hostalive/ping`)
    .then(Boolean)
    .catch(error => Promise.resolve(Boolean(error.response)))
    .then(online =>
      Promise.all([
        online ? keycloak.init().then(() => online) : Promise.resolve(online),
        getInitializationData(),
      ])
    )
    .then(([online, initializationData]) => {
      // Set telemetry context
      telemetry.verbose = __DEV__ || /debug=true/.test(history.location.search)

      keycloak.user.then(user => {
        telemetry.setContext('userId', user.id)
        telemetry.reportContext()
      })

      // Dispatch initial Redux action
      // Pull `user` from sync storage if offline to load an Assessment
      ;(online ? getUser() : getItem(SESSION_USER)).then(user => {
        store.dispatch(appInitialized({ ...initializationData, user }))
      })

      // Sync Redux routable with location
      store.dispatch(sync())

      // Store, styling, and router are set up, ready to render the React app
      return renderApp()
    })
    .then(() => {
      // Enable hot reloading for React components and Redux reducers
      if (module.hot) {
        module.hot.accept('./reducer', reloadRedux)
      }
    })

// Dispatch action on login
keycloak.onLogin(() => {
  getUser().then(user => store.dispatch(loggedIn(user)))
})

// Dispatch action on logout
keycloak.onLogout(() => {
  store.dispatch(loggedOut())
})

// Initialize the application
if (!__TEST__) {
  initApp()
}

// Register service worker
if (!__DEV__) {
  if ('serviceWorker' in navigator) {
    window.addEventListener('load', () => {
      navigator.serviceWorker.register('/service-worker.js')
    })
  }
}

export default initApp
