import { Redirect, Route, Router } from 'redux-routable'
import key from './key'

export const PARTNER_PORTAL_PATIENT_RECORD_ALLERGIES = `${key}.allergies`
export const PARTNER_PORTAL_PATIENT_RECORD_ATTACHMENTS = `${key}.attachments`
export const PARTNER_PORTAL_PATIENT_RECORD_CARE_PLAN = `${key}.carePlan`
export const PARTNER_PORTAL_PATIENT_RECORD_CARE_TEAM = `${key}.careTeam`
export const PARTNER_PORTAL_PATIENT_RECORD_DEMOGRAPHICS = `${key}.demographics`
export const PARTNER_PORTAL_PATIENT_RECORD_HISTORY_ADMITS = `${key}.historyAdmits`
export const PARTNER_PORTAL_PATIENT_RECORD_HISTORY_ASSESSMENTS = `${key}.historyAssessments`
export const PARTNER_PORTAL_PATIENT_RECORD_HISTORY_DISCHARGES = `${key}.historyDischarges`
export const PARTNER_PORTAL_PATIENT_RECORD_MEDICATIONS = `${key}.medications`
export const PARTNER_PORTAL_PATIENT_RECORD_ON_CALL = `${key}.onCall`
export const PARTNER_PORTAL_PATIENT_RECORD_PATIENT_INFO = `${key}.patientInfo`
export const PARTNER_PORTAL_PATIENT_RECORD_PHARMACIES = `${key}.pharmacies`
export const PARTNER_PORTAL_PATIENT_RECORD_SERVICE_INFO = `${key}.serviceInfo`
export const PARTNER_PORTAL_PATIENT_RECORD_STAKEHOLDERS = `${key}.stakeholders`
export const PARTNER_PORTAL_PATIENT_RECORD_ORDERS_INDEX = `${key}.ordersIndex`
export const PARTNER_PORTAL_PATIENT_RECORD_VITALS = `${key}.vitals`

export const PARTNER_PORTAL_PATIENT_RECORD_ORDERS_ROUTES = [
  PARTNER_PORTAL_PATIENT_RECORD_ORDERS_INDEX,
]

export default Router([
  Route(PARTNER_PORTAL_PATIENT_RECORD_ALLERGIES, '/:patientId/allergies'),
  Route(PARTNER_PORTAL_PATIENT_RECORD_ATTACHMENTS, '/:patientId/attachments'),
  Route(PARTNER_PORTAL_PATIENT_RECORD_CARE_PLAN, '/:patientId/care-plan'),
  Route(PARTNER_PORTAL_PATIENT_RECORD_CARE_TEAM, '/:patientId/aspire-team'),
  Route(PARTNER_PORTAL_PATIENT_RECORD_DEMOGRAPHICS, '/:patientId/demographics'),
  Route(PARTNER_PORTAL_PATIENT_RECORD_ALLERGIES, '/:patientId/allergies'),

  Route(
    PARTNER_PORTAL_PATIENT_RECORD_HISTORY_ADMITS,
    '/:patientId/history-admits'
  ),
  Route(
    PARTNER_PORTAL_PATIENT_RECORD_HISTORY_ASSESSMENTS,
    '/:patientId/history-assessments'
  ),
  Route(
    PARTNER_PORTAL_PATIENT_RECORD_HISTORY_DISCHARGES,
    '/:patientId/history-discharges'
  ),
  Route(PARTNER_PORTAL_PATIENT_RECORD_MEDICATIONS, '/:patientId/medications'),
  Route(PARTNER_PORTAL_PATIENT_RECORD_ON_CALL, '/:patientId/on-call'),
  Route(PARTNER_PORTAL_PATIENT_RECORD_PATIENT_INFO, '/:patientId/patient-info'),
  Route(PARTNER_PORTAL_PATIENT_RECORD_PHARMACIES, '/:patientId/pharmacies'),
  Route(PARTNER_PORTAL_PATIENT_RECORD_SERVICE_INFO, '/:patientId/service-info'),
  Route(PARTNER_PORTAL_PATIENT_RECORD_STAKEHOLDERS, '/:patientId/stakeholders'),
  Route(PARTNER_PORTAL_PATIENT_RECORD_ORDERS_INDEX, '/:patientId?/orders'),
  Route(PARTNER_PORTAL_PATIENT_RECORD_VITALS, '/:patientId/vitals'),
  Redirect(PARTNER_PORTAL_PATIENT_RECORD_PATIENT_INFO, '/:patientId'),
])
