import { Router, Scope } from 'redux-routable'
import notFoundRouter from '~/apps/notFound/router'
import partnerPortal from '~/partnerPortal/dashboard/router'
import patientRecordRouter from '~/partnerPortal/patientRecord/router'

// Please play nice and alphabetize your routers
export default Router([
  Scope('', partnerPortal),
  Scope('/patient-record', patientRecordRouter),
  Scope('', notFoundRouter),
])
