import { createMiddleware as createRoutableMiddleware } from 'redux-routable'
import history from '~/history'
import createStore from '~/utils/createStore'
import epic from './epic'
import reducer from './reducer'
import router from './router'

const routableMiddleware = createRoutableMiddleware(router, history)

export default createStore(reducer, { epic, routableMiddleware })
