import AspireAPI from '~/resources/aspire'
import Request from '~/utils/Request'

const key = 'partnerPortalDocuments'

export const fetchEncounterPDF = Request({
  typePrefix: key,
  typeBase: 'FETCH_ENCOUNTER',
  requestParams: ['patientId', 'encounterId'],
  operation: (patientId, encounterId) =>
    AspireAPI.get(
      `/partner_portal/patient/${patientId}/encounters/${encounterId}`,
      {
        responseType: 'blob',
      }
    ),
  messages: { failed: 'There was a problem fetching encounter' },
})

export const fetchOrderPDF = Request({
  typePrefix: key,
  requestParams: ['patientId', 'orderId'],
  typeBase: 'FETCH_ORDER',
  operation: (patientId, orderId) =>
    AspireAPI.get(`/partner_portal/patient/${patientId}/orders/${orderId}`, {
      responseType: 'blob',
    }),
  messages: { failed: 'There was a problem fetching order' },
})
