import { ofType } from 'redux-observable'
import { ignoreElements, tap } from 'rxjs/operators'
import { fetchEncounterPDF, fetchOrderPDF } from '../data/documents'

export default action$ =>
  action$.pipe(
    ofType(fetchEncounterPDF.SUCCEEDED, fetchOrderPDF.SUCCEEDED),
    tap(({ payload }) => {
      const url = window.URL.createObjectURL(payload)
      const link = document.createElement('a')

      link.setAttribute('href', url)
      link.setAttribute('target', '_blank')
      link.click()

      window.URL.revokeObjectURL(url)
    }),
    ignoreElements()
  )
