import { combineEpics } from 'redux-observable'
import { of } from 'rxjs'
import { catchError } from 'rxjs/operators'
import userSettings from '~/apps/userSettings/epic'
import { epicCrashed } from '~/data/epicError'
import { epic as fieldValues } from '~/data/fieldValues'
import connectivity from '~/epics/connectivity'
import errorLogger from '~/epics/errorLogger'
import loggedIn from '~/epics/loggedIn'
import patientPanelOpened from '~/epics/patientPanelOpened'
import toggleLights from '~/epics/toggleLights'
import unauthenticatedRequest from '~/epics/unauthenticatedRequest'
import patientRecord from '~/partnerPortal/patientRecord/epic'

// Please play nice and alphabetize your epics
export default (...args) =>
  combineEpics(
    connectivity,
    errorLogger,
    fieldValues,
    loggedIn,
    patientPanelOpened,
    patientRecord,
    toggleLights,
    unauthenticatedRequest,
    userSettings
  )(...args).pipe(catchError(error => of(epicCrashed(error))))
