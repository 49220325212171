import { combineReducers } from 'redux-immutable'
import panelOpen from '~/data/appLayout'
import connectivity from '~/data/connectivity'
import epicError from '~/data/epicError'
import fieldValues from '~/data/fieldValues'
import messages from '~/data/messages'
import pending from '~/data/pending'
import route from '~/data/route'
import session from '~/data/session'
import settings from '~/data/settings'
import { reducer as authorization } from '~/features/authorization'
import patientRecord from '~/partnerPortal/patientRecord/reducer'

// Please play nice and alphabetize your reducers
export default combineReducers({
  [authorization.key]: authorization,
  [connectivity.key]: connectivity,
  [epicError.key]: epicError,
  [fieldValues.key]: fieldValues,
  [messages.key]: messages,
  [panelOpen.key]: panelOpen,
  [patientRecord.key]: patientRecord,
  [pending.key]: pending,
  [route.key]: route,
  [settings.key]: settings,
  [session.key]: session,
})
