import { combineReducers } from 'redux-immutable'
import portal from './data/other'
import patientId from './data/patientId'
import patientInfo from './data/patientInfo'
import key from './key'

const {
  timelineEventsReducer,
  vitalsReducer,
  carePlanReducer,
  lastEncounterCarePlanReducer,
  contactsReducer,
  stakeholdersReducer,
  careTeamReducer,
  prescriptionsReducer,
  preferredPharmacyReducer,
  hospitalizationsReducer,
  ltcsReducer,
  snfsReducer,
  encountersReducer,
  ordersReducer,
  dischargesReducer,
  onCallsReducer,
  attachmentsReducer,
} = portal

const reducer = combineReducers({
  [patientId.key]: patientId,
  [patientInfo.key]: patientInfo,
  [timelineEventsReducer.key]: timelineEventsReducer,
  [vitalsReducer.key]: vitalsReducer,
  [carePlanReducer.key]: carePlanReducer,
  [lastEncounterCarePlanReducer.key]: lastEncounterCarePlanReducer,
  [contactsReducer.key]: contactsReducer,
  [stakeholdersReducer.key]: stakeholdersReducer,
  [careTeamReducer.key]: careTeamReducer,
  [prescriptionsReducer.key]: prescriptionsReducer,
  [preferredPharmacyReducer.key]: preferredPharmacyReducer,
  [hospitalizationsReducer.key]: hospitalizationsReducer,
  [ltcsReducer.key]: ltcsReducer,
  [snfsReducer.key]: snfsReducer,
  [encountersReducer.key]: encountersReducer,
  [ordersReducer.key]: ordersReducer,
  [dischargesReducer.key]: dischargesReducer,
  [onCallsReducer.key]: onCallsReducer,
  [attachmentsReducer.key]: attachmentsReducer,
})

reducer.key = key

export default reducer
