import { changedTo } from 'redux-routable'
import { distinctUntilChanged, filter, map } from 'rxjs/operators'
import AspireAPI from '~/resources/aspire'
import Request from '~/utils/Request'
import createReducer from '~/utils/createReducer'
import { get } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import { switchTo } from '~/utils/operators'
import { capitalizeFirst } from '~/utils/stringManipulation'
import rootKey from '../key'
import {
  PARTNER_PORTAL_PATIENT_RECORD_ATTACHMENTS,
  PARTNER_PORTAL_PATIENT_RECORD_CARE_PLAN,
  PARTNER_PORTAL_PATIENT_RECORD_CARE_TEAM,
  PARTNER_PORTAL_PATIENT_RECORD_DEMOGRAPHICS,
  PARTNER_PORTAL_PATIENT_RECORD_HISTORY_ADMITS,
  PARTNER_PORTAL_PATIENT_RECORD_HISTORY_ASSESSMENTS,
  PARTNER_PORTAL_PATIENT_RECORD_HISTORY_DISCHARGES,
  PARTNER_PORTAL_PATIENT_RECORD_MEDICATIONS,
  PARTNER_PORTAL_PATIENT_RECORD_ON_CALL,
  PARTNER_PORTAL_PATIENT_RECORD_ORDERS_INDEX,
  PARTNER_PORTAL_PATIENT_RECORD_PATIENT_INFO,
  PARTNER_PORTAL_PATIENT_RECORD_PHARMACIES,
  PARTNER_PORTAL_PATIENT_RECORD_STAKEHOLDERS,
  PARTNER_PORTAL_PATIENT_RECORD_VITALS,
} from '../router'
import { getPatientRecord } from './common/shared'
import { getPatientId } from './patientId'
import { fetchPatientInfo } from './patientInfo'

const create = ({ key, urlPostfix, route, params = {}, defaultValue = [] }) => {
  const request = Request({
    typePrefix: `${rootKey}/${key}`,
    typeBase: 'FETCH',
    requestParams: ['patientId'],
    operation: patientId =>
      AspireAPI.get(`/partner_portal/patient/${patientId}/${urlPostfix}`, {
        params,
      }),
    messages: { failed: 'There was a problem fetching Patient Info' },
  })

  const reducer = createReducer(key, defaultValue, {
    [fetchPatientInfo.REQUESTED]: () => defaultValue,
    [request.SUCCEEDED]: (_state, { payload }) => payload,
  })

  const selector = pipe(getPatientRecord, get(key))

  const epic = (action$, state$) =>
    action$.pipe(
      filter(changedTo(route)),
      switchTo(state$),
      map(getPatientId),
      distinctUntilChanged(),
      map(request.requested)
    )

  return {
    reducer,
    epic,
    selector,
  }
}

const results = [
  {
    key: 'timelineEvents',
    route: PARTNER_PORTAL_PATIENT_RECORD_PATIENT_INFO,
    urlPostfix: 'timeline_events',
  },
  {
    key: 'vitals',
    route: PARTNER_PORTAL_PATIENT_RECORD_VITALS,
    urlPostfix: 'vitals',
    params: { count: 5 },
  },
  {
    key: 'carePlan',
    route: PARTNER_PORTAL_PATIENT_RECORD_CARE_PLAN,
    urlPostfix: 'care_plan',
    defaultValue: { problems: [] },
  },
  {
    key: 'lastEncounterCarePlan',
    route: PARTNER_PORTAL_PATIENT_RECORD_CARE_PLAN,
    urlPostfix: 'last_encounter_care_plan',
    defaultValue: {},
  },
  {
    key: 'contacts',
    route: PARTNER_PORTAL_PATIENT_RECORD_DEMOGRAPHICS,
    urlPostfix: 'contacts',
  },
  {
    key: 'stakeholders',
    route: PARTNER_PORTAL_PATIENT_RECORD_STAKEHOLDERS,
    urlPostfix: 'stakeholders',
  },
  {
    key: 'careTeam',
    route: PARTNER_PORTAL_PATIENT_RECORD_CARE_TEAM,
    urlPostfix: 'care_team',
    defaultValue: {},
  },
  {
    key: 'prescriptions',
    route: PARTNER_PORTAL_PATIENT_RECORD_MEDICATIONS,
    urlPostfix: 'prescriptions',
  },
  {
    key: 'preferredPharmacy',
    route: PARTNER_PORTAL_PATIENT_RECORD_PHARMACIES,
    urlPostfix: 'preferred_pharmacy',
    defaultValue: {},
  },
  {
    key: 'hospitalizations',
    route: PARTNER_PORTAL_PATIENT_RECORD_HISTORY_ADMITS,
    urlPostfix: 'hospitalizations',
  },
  {
    key: 'ltcs',
    route: PARTNER_PORTAL_PATIENT_RECORD_HISTORY_ADMITS,
    urlPostfix: 'ltcs',
  },
  {
    key: 'snfs',
    route: PARTNER_PORTAL_PATIENT_RECORD_HISTORY_ADMITS,
    urlPostfix: 'snfs',
  },
  {
    key: 'encounters',
    route: PARTNER_PORTAL_PATIENT_RECORD_HISTORY_ASSESSMENTS,
    urlPostfix: 'encounters',
  },
  {
    key: 'orders',
    route: PARTNER_PORTAL_PATIENT_RECORD_ORDERS_INDEX,
    urlPostfix: 'orders',
  },
  {
    key: 'discharges',
    route: PARTNER_PORTAL_PATIENT_RECORD_HISTORY_DISCHARGES,
    urlPostfix: 'discharges',
    defaultValue: { discharges: [] },
  },
  {
    key: 'onCalls',
    route: [
      PARTNER_PORTAL_PATIENT_RECORD_ON_CALL,
      PARTNER_PORTAL_PATIENT_RECORD_PATIENT_INFO,
    ],
    urlPostfix: 'on_calls',
    defaultValue: { onCalls: [], legacyOnCalls: [] },
  },
  {
    key: 'attachments',
    route: PARTNER_PORTAL_PATIENT_RECORD_ATTACHMENTS,
    urlPostfix: 'attachments',
  },
].reduce((acc, config) => {
  const { key } = config
  const { selector, reducer, epic } = create(config)

  return {
    ...acc,
    [`get${capitalizeFirst(key)}`]: selector,
    [`${key}Reducer`]: reducer,
    [`${key}Epic`]: epic,
  }
}, {})

export default results
