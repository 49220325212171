import AspireAPI from '~/resources/aspire'
import Request from '~/utils/Request'
import createReducer from '~/utils/createReducer'
import { get } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import { getPatientRecord } from './common/shared'

const key = 'patientInfo'

const fetchPatientInfoApi = patientId =>
  AspireAPI.get(`/partner_portal/patient/${patientId}/info`)

export const fetchPatientInfo = Request({
  typePrefix: key,
  typeBase: 'FETCH',
  requestParams: ['patientId'],
  operation: fetchPatientInfoApi,
  messages: { failed: 'There was a problem fetching Patient Info' },
})

export default createReducer(
  key,
  {},
  {
    [fetchPatientInfo.REQUESTED]: () => ({}),
    [fetchPatientInfo.SUCCEEDED]: (_state, { payload }) => payload,
  }
)

export const getPatientInfo = pipe(getPatientRecord, get(key))
